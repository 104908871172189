import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import useHindiLangFont from '../hooks/useHindiLangFont';
import { Button } from './core/Button';
import { Section, SectionWrapper, StyledLink, Title } from './core/commonExports';


export default function ClaimSection({mlp=false}) {
  const {hindiBold} = useHindiLangFont()
  const {t} = useTranslation(['claimsSection', 'common'])
  return (
    <Section background="#ffffff" height="600px">
      <SectionWrapper>
        <Container>
          <HeadingContainer>
            <Title style={{ marginBottom: '40px', textTransform: mlp ? "uppercase" : "" }} className={hindiBold}>
              {t('ClaimWithUs')}
            </Title>
            <div className="on-desktop">
              <StyledLink to="/claims">
                <Button className={hindiBold} label={t('CTALabels.KnowMore')} wide type="secondary" />
              </StyledLink>
            </div>
          </HeadingContainer>
          <div className="claim-icon">
            <StaticImage 
              src="../assets/images/claims_icon.webp"
              alt="claims-support"
            />
          </div>
          <div className="on-mobile">
            <StyledLink to="/claims">
              <Button className={hindiBold} label={t('CTALabels.KnowMore')} wide type="secondary" />
            </StyledLink>
          </div>
        </Container>
      </SectionWrapper>
    </Section>
  );
}

const HeadingContainer = styled.div`
  margin-right: 80px;

  @media screen and (max-width: 768px) {
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .on-mobile {
      display: flex;
      justify-content: center;
    }
  }

  .claim-icon {
    margin-left: 80px;
    width: min(250px, 100%);

    @media screen and (max-width: 768px) {
      margin-left: 0;
      width: 250px;
      margin: 2.5rem 0;
    }
  }
`;
